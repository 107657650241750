/*
 * Plain, un-styled anchor tag that opens links in a new tab.
 * By default, `noreferrer noopener` is specified to prevent leaking referral info.
 * For any links to Pallet domains, you should set `allowReferrer` to `true`.
 */

import { textStyles, TextVariant } from "@pallet-hq/hegel"
import { AnchorHTMLAttributes } from "react"

type NewTabLinkProps = {
    href?: string
    variant?: TextVariant
    underline?: boolean
    allowReferrer?: boolean
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const NewTabLink = ({ allowReferrer, ...props }: NewTabLinkProps) => (
    <a
        target="_blank"
        rel={!allowReferrer ? undefined : "noopener noreferrer"}
        style={{
            color: "inherit",
            textDecoration: props.underline ? "underline" : "none",
            ...(props.variant ? textStyles[props.variant] : {}),
            cursor: props.href ? "pointer" : "auto",
        }}
        {...props}
    />
)
