import { gql } from "@apollo/client"

export const PALLET_HEAD_QUERY = gql`
    query PalletHeadQuery($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                primaryColor
                canEdit
                name
                subheading
                logo {
                    source
                }
                group {
                    id
                    name
                    description
                }
            }
        }
    }
`
