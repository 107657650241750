import { gql } from "@apollo/client"
import Navbar from "components/Navbar"
import AccountControl from "components/Navbar/AccountControl"
import { PalletNavbar_applylist } from "__generated__/PalletNavbar_applylist"
import NavigationMenu from "./NavigationMenu"
import PalletInfo from "./PalletInfo"

const PalletNavbarFragment = gql`
    fragment PalletNavbar_applylist on ApplylistType {
        id
        ...RenderOptions_applylist
        ...PalletNavbarInfo_applylist
    }
    ${NavigationMenu.fragment}
    ${PalletInfo.fragment}
`

type NavbarProps = {
    applylist?: PalletNavbar_applylist
}

const NavigationBar = (props: NavbarProps) => {
    return (
        <Navbar
            left={<PalletInfo {...props} />}
            center={<NavigationMenu {...props} />}
            right={<AccountControl {...props} />}
        />
    )
}

NavigationBar.fragment = PalletNavbarFragment

export default NavigationBar
