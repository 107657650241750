import React from "react"
import NextHead from "next/head"

export type HeadProps = {
    title?: string
    description?: string
    image?: string
    /** Is visible to search engine crawlers */
    isVisible?: boolean
}

const isProduction = process.env.NEXT_PUBLIC_ENV === "new-production"

const Head: React.FC<HeadProps> = ({
    title,
    description,
    image,
    isVisible,
}) => {
    const metaTitle = title || "Pallet: See what work can be"
    const robots =
        isProduction && isVisible ? "index, follow" : "noindex, nofollow"

    return (
        <NextHead>
            <title key="title">{metaTitle}</title>
            <meta key="og:title" property="og:title" content={metaTitle} />
            <meta name="robots" content={robots} />
            {description && (
                <>
                    <meta
                        key="description"
                        name="description"
                        content={description}
                    />
                    <meta
                        key="og:description"
                        property="og:description"
                        content={description}
                    />
                </>
            )}
            {image && (
                <meta key="og:image" property="og:image" content={image} />
            )}
        </NextHead>
    )
}

export default Head
