import { useQuery } from "@apollo/client"
import Head from "utils/Head"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { PalletHeadQuery } from "__generated__/PalletHeadQuery"
import { PALLET_HEAD_QUERY } from "./queries"

type PalletHeadProps = {
    collectiveHead?: boolean
}

export const PalletHead = (props: PalletHeadProps) => {
    const { pallet: slug } = usePalletContext()

    const { data } = useQuery<PalletHeadQuery>(PALLET_HEAD_QUERY, {
        variables: { slug: slug! },
        // Should get the data from server side request. Throw error if data is not available
        fetchPolicy: "cache-only",
        ssr: true,
    })

    if (!data || data.applylist === null) return null

    const useCollectiveInfo = props.collectiveHead && !!data.applylist.group
    return (
        <Head
            title={
                useCollectiveInfo
                    ? data.applylist.group!.name
                    : data.applylist.name
            }
            description={
                useCollectiveInfo
                    ? data.applylist.group!.description
                    : data.applylist.subheading
            }
            image={data.applylist.logo?.source}
            isVisible
        />
    )
}
