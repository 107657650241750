import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import { NewTabLink } from "components/Link/NewTabLink"

export const CompanyLink = styled(NewTabLink)`
    text-decoration: none;

    color: ${theme.colors.gray900};
    &:hover {
        text-decoration: underline;
    }
`

export const ResponsivePageWidth = styled.div`
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    ${mq({
        padding: [
            `0 ${theme.space.small}`,
            `0 ${theme.space.large}`,
            `0 ${theme.space.xxlarge}`,
        ],
    })}
`
export const FullWidthPage = styled.div`
    width: 100%;
`

export const FullPageColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const PageContainer = styled.div`
    height: 100%;
    display: flex;
    flex: 0;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: ${theme.space.large};
`
