import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { mq, Text } from "@pallet-hq/hegel"
import { ReactComponent as PalletLogo } from "components/icons/logos/pallet/logomark.svg"
import Image from "components/Image"
import SmartLink from "components/Link/SmartLink"
import { PalletNavbarInfo_applylist } from "__generated__/PalletNavbarInfo_applylist"

const PalletInfoFragment = gql`
    fragment PalletNavbarInfo_applylist on ApplylistType {
        id
        name
        curatorAvatar {
            source
        }
        showFullNavbar
    }
`

type PalletInfoProps = {
    applylist?: PalletNavbarInfo_applylist
}

const PalletInfo = (props: PalletInfoProps) => {
    return (
        <PalletInfoDiv>
            <SmartLink href="/">
                {props.applylist?.showFullNavbar ? (
                    <>
                        <CuratorImage
                            src={props.applylist.curatorAvatar.source}
                            colorSlug={props.applylist.name}
                            height={32}
                            width={32}
                            borderRadius="50%"
                        />
                        <Text variant="uiLarge500" ml="small">
                            {props.applylist.name}
                        </Text>
                    </>
                ) : (
                    <PalletLogo
                        width={107}
                        height={24}
                        style={{ cursor: "pointer" }}
                    />
                )}
            </SmartLink>
        </PalletInfoDiv>
    )
}

PalletInfo.fragment = PalletInfoFragment

const CuratorImage = styled(Image)`
    object-fit: cover;
`

const PalletInfoDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    ${mq({ width: ["auto", "100%"] })};
`

export default PalletInfo
