import { gql, useQuery } from "@apollo/client"
import { ThemeProvider } from "@emotion/react"
import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import { TRACK_EVENTS } from "constants/events"
import mixpanel from "mixpanel-browser"
import { FullWidthPage } from "page-components/atoms/AppPages"
import React, { ReactNode, useEffect } from "react"
import { PalletContextProvider } from "utils/hooks/usePalletContext"
import { PalletContext } from "utils/router"
import { PalletNavbarLayoutQuery } from "__generated__/PalletNavbarLayoutQuery"
import Footer from "./Footer"
import NavigationBar from "./NavigationBar"
import { PalletHead } from "./PalletHead"

const PALLET_NAVBAR_LAYOUT_QUERY = gql`
    query PalletNavbarLayoutQuery($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                slug
                primaryColor
                ...PalletNavbar_applylist
                ...PalletFooter_applylist
            }
        }
    }
    ${NavigationBar.fragment}
    ${Footer.fragment}
`

type NavbarLayoutLayoutProps = {
    palletContext: PalletContext
    showFooter?: boolean
    children?: ReactNode
    collectiveHead?: boolean // Show metadata for collectives
}

const NavbarLayout = ({
    children,
    showFooter = true,
    collectiveHead = false,
    palletContext,
}: NavbarLayoutLayoutProps) => {
    const { data } = useQuery<PalletNavbarLayoutQuery>(
        PALLET_NAVBAR_LAYOUT_QUERY,
        { variables: { slug: palletContext.pallet! } }
    )

    useEffect(() => {
        mixpanel.track(TRACK_EVENTS.JOB_BOARD_VIEWED, {
            applylist_slug: palletContext.pallet!,
        })
    }, [palletContext.pallet])

    if (data?.applylist === null) return null

    return (
        <>
            <ThemeProvider
                theme={{
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary:
                            data?.applylist?.primaryColor ||
                            theme.colors.primary,
                    },
                }}
            >
                <PalletContextProvider value={palletContext}>
                    <PalletHead collectiveHead={collectiveHead} />
                    <Wrapper>
                        <NavigationBar applylist={data?.applylist} />
                        <PageWrapper>{children}</PageWrapper>
                        {showFooter && <Footer applylist={data?.applylist} />}
                    </Wrapper>
                </PalletContextProvider>
            </ThemeProvider>
        </>
    )
}

const Wrapper = styled(FullWidthPage)`
    height: 100%;
`

const PageWrapper = styled(FullWidthPage)`
    min-height: calc(100vh - 64px);
`

export default NavbarLayout
